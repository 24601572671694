<template>
  <div :style="cssVariables">
    <div>
      <!-- Desktop header -->
      <div class="tw-hidden md:tw-block tw-z-header tw-fixed tw-top-0 tw-inset-x-0">
        <!-- Verify profile banner -->
        <UiBanner
          v-if="banners.verify"
          color="primary"
        >
          {{ $t('verify-email.message') }}

          <template #actions>
            <UiButton
              size="sm"
              variant="secondary"
              @click="resendVerificationMail"
            >
              {{ $t('verify-email.resend') }}
            </UiButton>
          </template>
        </UiBanner>

        <!-- Impersonating banner -->
        <UiBanner
          v-if="banners.impersonating"
          color="primary"
        >
          <div class="tw-flex tw-items-center">
            <span class="tw-inline-flex tw-p-1 tw-rounded-full tw-bg-primary-100 tw-dark:bg-gray-600 tw-w-6 tw-h-6 tw-items-center tw-justify-center">
              <UiIcon
                class="tw-text-primary-500"
                name="profile"
                solid
              />
            </span>
            <span class="tw-ml-3">
              You are impersonating {{ currentUser?.name }}'s account!
            </span>
          </div>

          <template #actions>
            <UiButton
              size="sm"
              variant="secondary"
              @click="stopImpersonating"
            >
              Stop Impersonating
            </UiButton>
          </template>
        </UiBanner>

        <!-- App header -->
        <div class="tw-bg-gray-50">
          <div class="tw-max-w-8xl tw-mx-auto tw-h-[--header-height] tw-px-8 tw-flex tw-items-center tw-gap-6">
            <div class="tw-w-2/6 tw-flex">
              <RouterLink to="/">
                <UiLogo
                    class="tw-w-auto tw-h-8 lg:tw-h-8"
                />
              </RouterLink>
            </div>

            <div class="tw-w-2/6">
              <div class="tw-flex tw-justify-center">
                <div class="tw-flex tw-items-center tw-justify-center tw-py-4 tw-px-5 tw-space-x-8 tw-rounded-full tw-bg-white tw-shadow">
                  <UiIconItem
                    :active="$route.path === '/'"
                    icon="home"
                    to="/"
                  />
                  <UiIconItem
                    :active="$route.path === '/creators'"
                    icon="discovery"
                    to="/creators"
                  />
                  <UiIconItem
                    :active="$route.path.startsWith('/messages')"
                    icon="message"
                    to="/messages"
                    :badge="updates.messages > 0"
                  />
                  <UiIconItem
                    :active="isNotificationsSlideOverOpen"
                    icon="notification"
                    :badge="updates.notifications > 0"
                    @click="isNotificationsSlideOverOpen = true"
                  />
                  <UiIconItem
                    v-if="currentUser?.isCreator"
                    :active="$route.path === '/posts/create'"
                    icon="plus"
                    to="/posts/create"
                  />
                </div>
              </div>
            </div>

            <div class="tw-hidden lg:tw-block tw-w-2/6">
              <div class="tw-ml-auto tw-w-side">
                <div
                  class="tw-py-4 tw-px-5 tw-flex tw-items-center tw-bg-white tw-text-gray-500 tw-rounded-2xl tw-cursor-pointer"
                  @click="isSearchSlideOverOpen = true"
                >
                  <UiIcon
                    name="search"
                  />
                  <span class="tw-ml-3">
                    {{ $t('general.search') }}
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- Page -->
      <div
        class="tw-max-w-8xl tw-mx-auto sm:tw-px-6 md:tw-px-8 md:tw-pb-10"
        :class="{ 'tw-pb-20': isTabBarVisible }"
      >
        <!-- App menu -->
        <div
          v-if="isAppMenuVisible"
          class="tw-hidden lg:tw-block tw-z-header tw-fixed tw-inset-0 tw-top-[--top] tw-left-[max(0px,calc(50%-45rem))] tw-right-auto tw-pl-8 tw-overflow-hidden"
        >
          <div class="tw-pb-10 tw-h-full tw-w-side tw-overflow-auto">
            <UiCard class="tw-shadow">
              <AppMenu />
            </UiCard>
          </div>
        </div>

        <!-- Main content -->
        <div
          class="md:tw-pt-[--top]"
          :class="{ 'lg:tw-ml-side lg:tw-pl-6': isAppMenuVisible }"
        >
          <div
            class="tw-mx-auto xl:tw-max-w-none"
            :class="{
              'tw-max-w-3xl': isAppMenuVisible,
              'xl:tw-ml-0 xl:tw-mr-side xl:tw-pr-6': isSideVisible,
            }"
          >
            <div
              v-if="$route.path === '/'"
              class="tw-z-header tw-sticky tw-top-0 tw-bg-white md:tw-hidden"
            >
              <AppHeader>
                <UiIconItem
                  :active="$route.path === '/search'"
                  icon="search"
                  to="/search"
                />
                <UiIconItem
                  :active="isNotificationsSlideOverOpen"
                  icon="notification"
                  :badge="updates.notifications > 0"
                  @click="isNotificationsSlideOverOpen = true"
                />
                <UiIconItem
                  icon="menu"
                  @click="isMenuSlideOverOpen = true"
                />
              </AppHeader>
            </div>

            <div
              v-if="isContestButtonVisible"
              class="tw-mb-6 tw-px-4 tw-space-y-2 sm:tw-px-0 xl:tw-hidden"
            >
              <UiButton
                v-for="contest in contests"
                :key="contest.slug"
                alternate
                class="tw-w-full"
                variant="primary"
                icon="star"
                :to="contest.url"
              >
                {{ contest.name }}
              </UiButton>
            </div>

            <RouterView />

            <div
              v-if="spinner"
              class="
                tw-fixed tw-top-1/2 tw-inset-x-0
                tw-flex tw-justify-center tw-z-spinner
                lg:tw-left-[22rem]
              "
              :class="{ 'xl:tw-right-[22rem]': isSideVisible }"
            >
              <UiSpinner />
            </div>
          </div>

          <UiSecurityCodeModal
            v-if="currentUser?.should_prompt_security_code"
            :title="$t('security-code.header')"
            @submit="setSecurityCode"
            class="tw-text-sm"
          >
            <div
              class="tw-pb-"
              v-html="$t('security-code.notice')"
            />

            <div
              class="tw-py-5 tw-text-error"
              v-html="$t('security-code.warning')"
            />
          </UiSecurityCodeModal>

          <!-- Side -->
          <div
            class="tw-hidden tw-fixed tw-top-[--top] tw-bottom-0 tw-right-[max(0px,calc(50%-45rem))] tw-pb-10 tw-pr-8 tw-overflow-y-auto"
            :class="{ 'xl:tw-block': isSideVisible }"
          >
            <div class="tw-w-side">
              <div
                v-if="isContestButtonVisible"
                class="tw-mb-6 tw-space-y-2"
              >
                <UiButton
                  v-for="contest in contests"
                  :key="contest.slug"
                  alternate
                  class="tw-w-full"
                  variant="primary"
                  icon="star"
                  :to="contest.url"
                >
                  {{ contest.name }}
                </UiButton>
              </div>

              <RouterView
                name="side"
              />
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- Bottom Tab Bar -->
    <div
      v-if="isTabBarVisible"
      class="tw-z-header tw-fixed tw-inset-x-0 tw-bottom-[-1px] tw-w-full tw-pt-4 tw-pb-6 tw-flex tw-items-center tw-justify-evenly tw-bg-white tw-rounded-t-2xl tw-border-t tw-border-gray-100 md:tw-hidden"
    >
      <UiIconItem
        :active="$route.path === '/'"
        icon="home"
        :label="$t('general.home')"
        to="/"
      />
      <UiIconItem
        :active="$route.path === '/creators'"
        icon="3-user"
        :label="$t('general.models')"
        to="/creators"
      />

      <RouterLink
        class="tw-flex tw-items-center tw-justify-center tw-bg-primary-500 tw-text-white tw-rounded-full hover:tw-text-white hover:tw-bg-primary-500/90"
        :to="currentUser?.isCreator ? '/posts/create' : '/payouts'"
      >
        <div>
          <svg class="tw-h-10 tw-w-10" width="24" height="24" viewBox="0 0 24 24" stroke="currentColor" xmlns="http://www.w3.org/2000/svg">
            <path d="M12.0368 8.46265V15.6111" stroke-width="1" stroke-linecap="round" stroke-linejoin="round" />
            <path d="M15.6146 12.0368H8.4588" stroke-width="1" stroke-linecap="round" stroke-linejoin="round" />
          </svg>
        </div>
      </RouterLink>

      <UiIconItem
        :active="$route.path === '/messages'"
        icon="message"
        :label="$t('general.message')"
        to="/messages"
      />
      <UiIconItem
        :active="$route.path === currentUser.url"
        icon="profile"
        :label="$t('general.profile')"
        :to="currentUser.url"
      />
    </div>

    <UiSlideOver
      v-if="isMenuSlideOverOpen"
      backdrop
      position="start"
      @close="isMenuSlideOverOpen = false"
    >
      <div>
        <AppHeader>
          <UiIconItem
            :active="$route.path === '/search'"
            icon="search"
            to="/search"
          />
          <UiIconItem
            :active="isNotificationsSlideOverOpen"
            icon="notification"
            :badge="updates.notifications > 0"
            @click="isNotificationsSlideOverOpen = true"
          />
          <UiIconItem
            icon="close"
            @click="isMenuSlideOverOpen = false"
          />
        </AppHeader>

        <div class="tw-p-4">
          <AppMenu />
        </div>
      </div>
    </UiSlideOver>

    <UiSlideOver
      v-if="isNotificationsSlideOverOpen"
      position="end"
      @close="isNotificationsSlideOverOpen = false"
    >
      <div>
        <div class="md:tw-py-3 md:tw-px-2">
          <UiNavigationBar
            :title="$t('general.notifications')"
            responsive
            @back="isNotificationsSlideOverOpen = false"
          >
            <button
              class="tw-hidden md:tw-block"
              @click="isNotificationsSlideOverOpen = false"
            >
              <UiIcon
                name="close"
              />
            </button>
          </UiNavigationBar>
        </div>

        <div class="tw-px-4 tw-pb-8">
          <PageNotifications />
        </div>
      </div>
    </UiSlideOver>

    <UiSlideOver
      v-if="isSearchSlideOverOpen"
      v-slot="{ container }"
      position="end"
      @close="isSearchSlideOverOpen = false"
    >
      <div class="tw-py-4">
        <PageSearch
          v-if="container"
          :container="container"
          dismissible
          @dismiss="isSearchSlideOverOpen = false"
        />
      </div>
    </UiSlideOver>
  </div>
</template>

<style>
@media screen(md) {
  body {
    background-color: theme('colors.gray.50');
  }
}
</style>

<script>
import Contest from '@/components/models/Contest';

import AppMenu from '@/components/layout/AppMenu.vue';
import UiCard from '@/components/ui/UiCard.vue';
import UiIconItem from '@/components/ui/UiIconItem.vue';
import UiLogo from '@/components/ui/UiLogo.vue';
import UiSlideOver from '@/components/ui/UiSlideOver.vue';
import UiIcon from '@/components/ui/UiIcon.vue';
import PageSearch from '@/components/pages/PageSearch.vue';
import AppHeader from '@/components/layout/AppHeader.vue';
import PageNotifications from '@/components/pages/PageNotifications.vue';
import UiNavigationBar from '@/components/ui/UiNavigationBar.vue';
import UiSpinner from '@/components/ui/UiSpinner.vue';
import UiButton from '@/components/ui/UiButton.vue';
import UiBanner from '@/components/ui/UiBanner.vue';
import UiSecurityCodeModal from "@/components/ui/UiSecurityCodeModal.vue";

export default {
  components: {
    UiSecurityCodeModal,
    UiBanner,
    UiButton,
    UiSpinner,
    UiNavigationBar,
    PageNotifications,
    AppHeader,
    PageSearch,
    UiIcon,
    UiSlideOver,
    AppMenu,
    UiCard,
    UiIconItem,
    UiLogo,
  },
  data() {
    return {
      contests: [],
      isMenuSlideOverOpen: false,
      isNotificationsSlideOverOpen: false,
      isSearchSlideOverOpen: false,
    };
  },
  computed: {
    isImpersonating() {
      return this.$store.state.impersonating == 'true';
    },
    spinner() {
      return this.$store.state.spinner &&
        !this.isSearchSlideOverOpen &&
        this.$route.path !== '/search';
    },
    currentUser() {
      return this.$store.state.currentUser;
    },
    updates() {
      return this.$store.state.updates;
    },
    isVerified() {
      return this.currentUser?.email_verified;
    },
    isAppMenuVisible() {
      return !this.$route.path.startsWith('/messages');
    },
    isSideVisible() {
      return this.$route.name === 'search' || this.$route.path === '/';
    },
    isTabBarVisible() {
      const routes = [
        '/',
        '/messages',
        '/creators',
        '/search',
      ];

      return routes.includes(this.$route.path);
    },
    isContestButtonVisible() {
      return this.contests.length > 0 && this.$route.path === '/';
    },
    banners() {
      return {
        verify: !this.isVerified,
        impersonating: this.isImpersonating,
      };
    },
    activeBannersValues() {
      return Object.values(this.banners).filter(Boolean);
    },
    cssVariables() {
      return {
        '--header-height': '6rem',
        '--banner-height': '3rem',
        '--top': `calc(var(--header-height) + var(--banner-height) * ${this.activeBannersValues.length})`,
      };
    },
  },
  created() {
    this.loadContests();
  },
  methods: {
    setSecurityCode(pin) {
      this.$post(
        '/auth/security-code',
          { security_code: pin },
          () => {
            this.$toast({
              title: this.$t('general.success'),
              description: this.$t('security-code.confirm'),
              variant: 'success',
            });

            // Set condition manually to hide modal
            this.currentUser.should_prompt_security_code = false;

            // Prevent the modal to re-open as session sync with localStorage
            const user = JSON.parse(localStorage.currentUser);
            user.should_prompt_security_code = false;
            localStorage.currentUser = JSON.stringify(user);
          }
      )
    },
    resendVerificationMail() {
      this.$post(
        '/auth/send-verification-email',
        null,
        () => {
          this.$toast({
            title: this.$t('verify-email.sent-title'),
            description: this.$t('verify-email.sent-description'),
            variant: 'success',
          });
        },
        () => {
          this.$toast({
            title: this.$t('verify-email.pending-title'),
            description: this.$t('verify-email.pending-description'),
            variant: 'error',
          });
        },
      );
    },
    stopImpersonating() {
      this.$post(
        '/auth/logout',
        {},
        () => {},
        () => {
        },
      );
      this.$saveToken(null);
      this.$saveUser(null);
      window.location.href = '/';
    },
    loadHelpScout() {
      !function (e, t, n) {
        function a() {
          var e = t.getElementsByTagName('script')[0],
            n = t.createElement('script');
          n.type = 'text/javascript', n.async = !0, n.src = 'https://beacon-v2.helpscout.net', e.parentNode.insertBefore(n, e);
        }

        if (e.Beacon = n = function (t, n, a) {
          e.Beacon.readyQueue.push({
            method: t,
            options: n,
            data: a,
          });
        }, n.readyQueue = [], 'complete' === t.readyState) return a();
        e.attachEvent ? e.attachEvent('onload', a) : e.addEventListener('load', a, !1);
      }(window, document, window.Beacon || function () {});

      window.Beacon('config', {
        hideFABOnMobile: true,
      });
      window.Beacon('init', '1d7c85fc-79e3-4d32-9441-2bf3c2e892c8');
    },
    loadContests() {
      this.$get(
        '/contests',
        (data) => {
          let contests = [];
          for (let obj of data.data) {
            contests.push(new Contest(obj));
          }
          this.contests = contests;
        },
        (errors) => {
          console.log(errors);
        },
      );
    },
  },
  watch: {
    currentUser() {
      if (this.currentUser?.is_ban) {
        window.location.href = process.env.VUE_APP_API_URL + '/account-disabled';
      }
    },
    $route() {
      this.isMenuSlideOverOpen = false;
      this.isNotificationsSlideOverOpen = false;
    },
  },
  mounted() {
    if (this.$route.query.ve) {
      const isValid = this.$route.query.ve === "1";
      this.$toast({
        title: this.$t(isValid ? 'general.success' : 'general.error'),
        description:this.$t( isValid ? 'verify-email.success' : 'verify-email.already-validated'),
        duration: 10000,
        variant: isValid ? 'success' : 'error',
      })
    }
  },
};
</script>
