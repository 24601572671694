<template>
  <div>
    <div
      v-if="layout === 'grid'"
      class="tw-grid tw-grid-cols-3 tw-gap-2.5 sm:tw-gap-4"
    >
      <template
        v-for="(post, key) in posts"
      >
        <UiPostTile
          v-if="post.media.length > 0"
          :key="post.id"
          v-model="posts[key]"
          :sizes="sizes"
          @preview="onPreview"
        />
      </template>

      <template v-if="loading">
        <UiSkeleton
          v-for="i in 6"
          :key="i"
          class="tw-aspect-square"
        />
      </template>
    </div>

    <div
      v-else
      class="tw-space-y-4"
    >
      <div
        v-for="(post, key) in posts"
        :key="key"
      >
        <UiCard v-if="post !== null">
          <UiPost
            v-model="posts[key]"
            :sizes="sizes"
            @preview="onPreview"
          />
        </UiCard>
      </div>

      <template v-if="loading">
        <UiCard
          v-for="i in 3"
          :key="i"
        >
          <div class="tw-flex tw-items-center">
            <UiSkeleton
              class="tw-size-16"
              rounded="full"
            />
            <div class="tw-ml-2 tw-space-y-2">
              <UiSkeleton class="tw-h-3 tw-w-16" />
              <UiSkeleton class="tw-h-3 tw-w-32" />
            </div>
          </div>
          <UiSkeleton class="tw-mt-4 tw-h-3 tw-w-64" />
          <UiSkeleton class="tw-mt-4 tw-aspect-video" />
        </UiCard>
      </template>
    </div>

    <UiPostPreview
      v-if="preview"
      :value="preview"
      @del="del"
      @close="preview = false"
    />
  </div>
</template>

<script>
import UiCard from '@/components/ui/UiCard.vue';
import UiPost from '@/components/ui/UiPost.vue';
import UiPostPreview from '@/components/ui/UiPostPreview.vue';
import UiPostTile from '@/components/ui/UiPostTile.vue';
import UiSkeleton from '@/components/ui/UiSkeleton.vue';

const SCREEN_MD = 768;

export default {
  components: {
    UiCard,
    UiPost,
    UiPostPreview,
    UiPostTile,
    UiSkeleton,
  },
  props: {
    value: [],
    layout: String,
    sizes: [String, Object],
    loading: Boolean,
  },
  data() {
    return {
      preview: null,
    };
  },
  computed: {
    posts: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit('input', val);
      },
    },
  },
  methods: {
    onPreview(post) {
      if (window.innerWidth < SCREEN_MD) {
        this.$router.push(post.url);
      } else {
        this.preview = post;
      }
    },
    del(post) {
      this.posts = this.posts.filter(p => p.id !== post.id);
    },
  },
};
</script>
