<template>
  <div class="tw-relative tw-pb-[100%] tw-rounded-2xl tw-cursor-pointer">
    <div class="tw-absolute tw-inset-0 tw-h-full tw-w-full">
      <UiImage
        v-if="hasAccess"
        class="tw-rounded-2xl"
        fill
        :sizes="sizes"
        :src="thumbnail"
        :srcset="thumbnails"
        @click="$emit('preview', post)"
      />

      <UiLockedMedia
        v-else
        class="tw-h-full"
        :images="post.images"
        :price="post.price && post.priceFormatted"
        :videos="post.videos"
        @unlock="unlock"
      />
    </div>
  </div>
</template>

<script>
import Payment from '@/components/models/Payment';
import Post from '@/components/models/Post';

import UiImage from '@/components/ui/UiImage.vue';
import UiLockedMedia from '@/components/ui/UiLockedMedia.vue';

export default {
  components: {
    UiImage,
    UiLockedMedia,
  },
  props: {
    value: Post,
    sizes: [String, Object],
  },
  computed: {
    post: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit('input', val);
      },
    },
    media() {
      return this.post.media[0];
    },
    thumbnail() {
      if (this.media.type === 0) {
        return this.media.url;
      }

      if (this.media.type === 1) {
        return this.media.screenshot;
      }

      return null;
    },
    thumbnails() {
      if (this.media.type === 0) {
        return this.media.signed_urls;
      }

      return null;
    },
    hasAccess: function () {
      return this.post.hasAccess;
    },
  },
  methods: {
    unlock() {
      if (this.post.isFree) {
        this.$buyItem({
          type: null,
          user: this.post.user,
        });
      } else {
        this.$buyItem({
          type: Payment.TYPE_POST,
          post: this.post,
          success: (data) => {
            this.post = new Post(data);
          },
        });
      }
    },
  },
};
</script>
