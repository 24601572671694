<template>
  <form
    @submit.prevent="search"
  >
    <UiFormInput
      v-model="q"
      autofocus
      icon="search"
      iconStyle="outline"
      name="search"
      type="text"
      :placeholder="$t('general.search')"
      @input="onInput"
    />
  </form>
</template>

<script>
import UiFormInput from '@/components/ui/UiFormInput.vue';

export default {
  components: {
    UiFormInput,
  },
  props: ['value'],
  data() {
    return {
      timer: null,
    };
  },
  computed: {
    q: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit('input', val);
      },
    },
  },
  methods: {
    search() {
      if (this.q.length === 0 || this.q.length >= 3) {
        this.$emit('search');
      }
    },
    onInput() {
      clearTimeout(this.timer);

      this.timer = setTimeout(() => {
        this.search();
      }, 300);
    },
  },
};
</script>
