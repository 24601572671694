<template>
  <div class="tw-group tw-relative tw-pb-[135%] tw-bg-gray-100 tw-rounded-2xl tw-overflow-hidden md:tw-pb-[110%] lg:md:tw-pb-[130%] xl:tw-pb-[110%]">
    <!-- Details -->
    <div
      class="tw-absolute tw-inset-0 tw-h-full tw-w-full tw-rounded-2xl tw-overflow-hidden tw-transition-opacity tw-duration-150 tw-ease-out group-hover:tw-ease-out"
      :class="unveiled ? 'tw-opacity-100' : 'tw-opacity-0 md:group-hover:tw-opacity-100'"
    >
      <div class="tw-h-full tw-w-full tw-flex tw-flex-col">
        <div class="tw-relative tw-basis-1/4 tw-overflow-hidden">
          <img
            v-if="user.cover_url"
            class="tw-absolute tw-inset-0 tw-h-full tw-w-full tw-object-cover"
            :src="user.cover_url"
          >
        </div>

        <div class="-tw-mt-12 tw-basis-full tw-flex tw-flex-col tw-items-center tw-text-center md:-tw-mt-8 xl:-mt-mt-12 2xl:-tw-mt-14">
          <UiAvatar
            :online="user.is_online"
            :src="user.avatar_url"
            :text="user.initials"
            :to="user.url"
            :size="{ initial: 'lg', md: 'sm', xl: 'md', '2xl': 'lg' }"
          />

          <UiUsername
            :as-link="false"
            class="tw-mt-2 tw-px-4 tw-max-w-full tw-text-lg tw-font-semibold tw-text-primary-500 md:tw-text-base"
            :user="user"
          />

          <div class="tw-text-lg tw-text-gray-500 tw-truncate sm:tw-text-sm">
            {{ '@' + user.username }}
            <span v-if="user.is_online">
              - {{ $t('general.online') }}
            </span>
          </div>

          <div class="tw-grow tw-flex tw-items-center tw-justify-center">
            <div class="tw-space-y-2 sm:tw-space-y-1">
              <UiButton
                class="tw-w-full"
                :size="{ initial: 'lg', sm: 'sm', xl: 'md' }"
                :to="user.url"
                variant="primary"
              >
                {{ $t('general.view-profile') }}
              </UiButton>
<!--              <UiButton-->
<!--                class="tw-w-full"-->
<!--                :size="{ initial: 'lg', sm: 'sm', xl: 'md' }"-->
<!--                :to="'/messages/' + user.username"-->
<!--                variant="outline"-->
<!--              >-->
<!--                {{ $t('general.message') }}-->
<!--              </UiButton>-->
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- Cover -->
    <div
      class="tw-absolute tw-inset-0 tw-h-full tw-w-full tw-bg-no-repeat tw-bg-center tw-bg-cover tw-rounded-2xl tw-overflow-hidden tw-transition-opacity tw-duration-150 tw-ease-in group-hover:tw-ease-in tw-cursor-pointer md:tw-cursor-default"
      :class="unveiled ? 'tw-opacity-0 tw-pointer-events-none' : 'md:group-hover:tw-opacity-0 md:group-hover:tw-pointer-events-none'"
      :style="{ 'background-image': 'url(' + user.avatar_url + ')' }"
      @click="onPreview"
    >
      <div class="tw-h-full tw-w-full tw-flex tw-flex-col tw-items-center tw-justify-end tw-p-4 tw-text-white tw-bg-gradient-to-t tw-from-black/60 tw-to-30%">
        <UiUsername
          :as-link="false"
          class="tw-max-w-full tw-font-semibold"
          :user="user"
        />

        <div class="mt-1 tw-flex tw-flex-wrap tw-items-center tw-justify-center tw-text-center tw-gap-x-2 tw-text-sm">
          <span>{{ '@' + user.username }}</span>
          <span
            v-if="user.is_online"
            class="tw-inline-flex tw-items-center"
          >
            <span class="tw-block tw-mr-1 tw-h-3 tw-w-3 tw-rounded-full tw-bg-success-alt tw-border tw-border-white" />
            {{ $t('general.online') }}
          </span>
        </div>
      </div>
    </div>

    <!-- Close button -->
    <button
      v-if="closeable"
      class="tw-absolute tw-top-4 tw-right-4 tw-block tw-text-white tw-mix-blend-difference"
      @click="$emit('close')"
    >
      <UiIcon
        name="close"
      />
    </button>
  </div>
</template>

<script>
import Creator from '@/components/models/Creator';
import User from '@/components/models/User';

import UiUsername from '@/components/ui/UiUsername.vue';
import UiButton from '@/components/ui/UiButton.vue';
import UiAvatar from '@/components/ui/UiAvatar.vue';
import UiIcon from '@/components/ui/UiIcon.vue';

const SCREEN_MD = 768;

export default {
  components: {
    UiIcon,
    UiAvatar,
    UiButton,
    UiUsername,
  },
  props: {
    creator: Creator,
    unveiled: Boolean,
    closeable: Boolean,
  },
  computed: {
    user() {
      return new User({
        ...this.creator,
        is_creator: true,
      });
    },
  },
  methods: {
    onPreview() {
      if (window.innerWidth < SCREEN_MD) {
        this.$emit('preview', this.creator);
      }
    },
  },
};
</script>
