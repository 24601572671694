import LayoutUser from '../layout/user/Layout.vue';
import PagePost from '../pages/PagePost.vue';
import PageLogin from '../pages/PageLogin.vue';
import PageHome from '../pages/PageHome.vue';
import PageErrorNotFound from '../pages/PageErrorNotFound.vue';
import PageSearch from '../pages/PageSearch.vue';
import PageNewPost from '../pages/PageNewPost.vue';
import PageSettings from '../pages/PageSettings.vue';
import PageLanguage from '../pages/PageLanguage.vue';
import PageSecurity from '../pages/PageSecurity.vue';
import PagePassword from '../pages/PagePassword.vue';
import PageEmail from '../pages/PageEmail.vue';
import PageSignup from '../pages/PageSignup.vue';
import PagePasswordReset from '../pages/PagePasswordReset.vue';
import PageForgot from '../pages/PageForgot.vue';
import PageUser from '../pages/PageUser.vue';
import PageUserPublic from '../pages/PageUserPublic.vue';
import PageBookmarks from '../pages/PageBookmarks.vue';
import PageLists from '../pages/PageLists.vue';
import PageList from '../pages/PageList.vue';
import PageMessages from '../pages/PageMessages.vue';
import PageCreators from '../pages/PageCreators.vue';
import PagePrice from '../pages/PagePrice.vue';
import PagePaymentSuccess from '../pages/PagePaymentSuccess.vue';
import PagePaymentFailure from '../pages/PagePaymentFailure.vue';
import PageSubscriptions from '../pages/PageSubscriptions.vue';
import PageNotifications from '../pages/PageNotifications.vue';
import PagePayouts from '../pages/PagePayouts.vue';
import PagePayments from '../pages/PagePayments.vue';
import PageVerification from '../pages/PageVerification.vue';
import PageContest from '../pages/PageContest.vue';
import AppSide from '../layout/AppSide.vue';
import PageImpersonate from "../pages/PageImpersonate";
import PageVault from '@/components/pages/PageVault.vue';

export default [
  {
    path: '/',
    component: LayoutUser,
    children: [
      {
        path: '/payments/:attach?',
        component: PagePayments,
      },
      {
        path: '/payouts',
        component: PagePayouts,
      },
      {
        path: '/notifications',
        component: PageNotifications,
        name: 'notifications',
      },
      {
        path: '/subscriptions',
        component: PageSubscriptions,
        name: 'subscriptions',
      },
      {
        path: '/payment/failure',
        component: PagePaymentFailure,
      },
      {
        path: '/payment/success/:gateway',
        component: PagePaymentSuccess,
      },
      {
        path: '/creators',
        component: PageCreators,
      },
      {
        path: '/messages/:username',
        component: PageMessages,
        name: 'show-message',
      },
      {
        path: '/messages',
        component: PageMessages,
        name: 'messages',
      },
      {
        path: '/lists',
        component: PageLists,
      },
      {
        path: '/lists/:id',
        component: PageList,
        name: 'list',
      },
      {
        path: '/bookmarks',
        component: PageBookmarks,
      },
      {
        path: '/forgot',
        components: {
          default: PageForgot,
          login: PageForgot,
        },
      },
      {
        path: '/password-reset',
        components: {
          default: PagePasswordReset,
          login: PagePasswordReset,
        },
      },
      {
        path: '/signup',
        components: {
          default: PageSignup,
          login: PageSignup,
        },
      },
      {
        path: '/settings/price',
        component: PagePrice,
      },
      {
        path: '/settings/email',
        component: PageEmail,
      },
      {
        path: '/settings/password',
        component: PagePassword,
      },
      {
        path: '/settings/language',
        component: PageLanguage,
      },
      {
        path: '/settings',
        component: PageSettings,
      },
      {
        path: '/security',
        component: PageSecurity,
      },
      {
        path: '/posts/create',
        component: PageNewPost,
      },
      {
        path: '/posts/edit/:id',
        component: PageNewPost,
      },
      {
        path: '/contest/:slug',
        components: {
          default: PageContest,
          guest: PageContest,
        },
        name: 'contest',
      },
      {
        path: '/contest/:slug/:username',
        components: {
          default: PageContest,
          guest: PageContest,
        },
        name: 'contest-entry',
      },
      {
        path: '/search',
        components: {
          default: PageSearch,
          side: AppSide,
        },
        name: 'search',
      },
      {
        path: '/vault',
        component: PageVault,
      },
      {
        path: '/verify',
        component: PageVerification,
        name: 'verify',
      },
      {
        path: '/impersonate/:token',
        components: {
          default: PageImpersonate,
          login: PageImpersonate,
          guest: PageImpersonate,
        },
        name: 'impersonate',
      },
      {
        path: '/:username',
        components: {
          default: PageUser,
          login: PageSignup,
          guest: PageUserPublic,
        },
        name: 'profile',
      },
      {
        path: '/:id/:username',
        components: {
          default: PagePost,
          login: PageSignup,
        },
      },
      {
        path: '/',
        components: {
          default: PageHome,
          login: PageLogin,
          side: AppSide,
        },
      },
      {
        path: '*',
        components: {
          default: PageErrorNotFound,
          login: PageLogin,
          publicProfile: PageUserPublic,
        },
      },
    ],
  },
];
