<template>
  <component
    :is="to ? 'RouterLink' : 'button'"
    :class="className({ size, variant, radius: alternate ? 'alternate' : 'default', disabled })"
    :disabled="disabled"
    :to="to"
    @click="(event) => $emit('click', event)"
  >
    <UiIcon
      v-if="icon"
      class="tw-shrink-0 tw-mr-2"
      :class="size === 'sm' ? 'tw-h-4 tw-w-4' : 'tw-h-6 tw-w-6'"
      :name="icon"
      :solid="variant !== 'outline'"
    />

    <span>
      <slot />
    </span>

    <UiIcon
      v-if="alternate"
      name="chevron-right"
      class="tw-ml-auto"
    />
  </component>
</template>

<script>
import { tv } from 'tailwind-variants';

import UiIcon from '@/components/ui/UiIcon.vue';

export default {
  components: {
    UiIcon,
  },
  props: {
    icon: String,
    name: String,
    size: {
      type: [String, Object],
      default: 'md',
    },
    to: [String, Object],
    variant: String,
    alternate: Boolean,
    disabled: Boolean,
  },
  computed: {
    className() {
      return tv(
        {
          base: 'tw-inline-flex tw-items-center tw-justify-center tw-text-center tw-font-semibold tw-text-base',
          variants: {
            variant: {
              primary: 'tw-text-white tw-bg-primary-500 hover:tw-text-white hover:tw-bg-primary-500/90',
              secondary: 'tw-text-primary-500 tw-bg-primary-100 hover:tw-text-primary-500 hover:tw-bg-primary-100/80',
              outline: 'tw-text-black tw-border tw-border-primary-500 hover:tw-text-white hover:tw-bg-primary-500',
            },
            size: {
              sm: 'tw-py-1.5 tw-px-3 tw-text-xs',
              md: 'tw-py-3 tw-px-4 tw-text-base',
              lg: 'tw-py-4 tw-px-5 tw-text-base',
            },
            radius: {
              default: 'tw-rounded-full',
              alternate: 'tw-rounded-2xl',
            },
            disabled: {
              true: 'tw-opacity-50 tw-pointer-events-none',
            },
          },
          defaultVariants: {
            radius: 'default',
          },
        },
        {
          responsiveVariants: ['sm', 'xl', '2xl'],
        },
      );
    },
  },
};
</script>
