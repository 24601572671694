<template>
  <div class="tw-px-4 tw-bg-white md:tw-px-6">
    <div class="tw-sticky tw-top-0 tw-py-6 tw-bg-white tw-z-bar">
      <div class="tw-flex tw-items-center">
        <AppSearch
          v-model="q"
          class="tw-grow"
          @search="reset"
        />

        <button
          v-if="dismissible"
          class="tw-ml-4"
          @click="$emit('dismiss')"
        >
          <UiIcon
            name="close"
          />
        </button>
      </div>
    </div>

    <div>
      <!-- If there's a query, show potential results -->
      <div v-if="q">
        <div class="tw-mx-auto tw-flex">
          <UiTabItem
            :active="searchType === types.user"
            @click="onTabChange(types.user)"
          >
            <UiIcon
              name="profile"
              solid
            />
          </UiTabItem>
          <!--
          <UiTabItem
            :active="searchType === types.hashtag"
            @click="onTabChange(types.hashtag)"
          >
            <UiIcon
              name="hashtag"
              solid
            />
          </UiTabItem>
          -->
        </div>

        <!-- If there are results, show them -->
        <div
          v-if="results.length > 0"
          class="tw-mt-4 tw-space-y-3 md:tw-mt-6 md:tw-space-y-6"
        >
          <template>
            <UiSearchItem
              v-for="(item, index) in results"
              :key="index"
              v-model="results[index]"
            />
          </template>
        </div>

        <!-- If there's no results -->
        <div
          v-else-if="!isLoading"
          class="tw-mt-6 tw-text-center"
        >
          <img
            alt=""
            class="tw-inline-block tw-w-64"
            :src="require('@/assets/illustrations/not-found.svg')"
          >
          <div class="tw-mt-3 tw-font-semibold tw-text-xl md:tw-text-2xl">
            {{ $t('general.search-not-found-title') }}
          </div>
          <div class="tw-mt-3 md:tw-text-lg">
            {{ $t('general.search-not-found-message') }}
          </div>
        </div>
      </div>

      <!-- Else, show Recent searches -->
      <!--
      <div v-else>
        <div class="tw-flex tw-items-center tw-justify-between tw-py-3">
          <div class="tw-font-semibold md:tw-text-lg">
            {{ $t('general.recent') }}
          </div>
        </div>

        <div class="tw-mt-4 tw-space-y-3 md:tw-mt-3 md:tw-space-y-6">
          <UiSearchItem
            v-for="(item, index) in recent"
            :key="index"
            v-model="recent[index]"
            dismissible
            @dismiss="onDismiss(recent[index])"
          />
        </div>
      </div>
      -->

      <div
        v-if="spinner"
        class="tw-flex tw-justify-center tw-mt-8"
      >
        <UiSpinner />
      </div>
    </div>
  </div>
</template>
<script>
import AppSearch from '@/components/layout/AppSearch.vue';
import UiIcon from '@/components/ui/UiIcon.vue';
import UiSpinner from '@/components/ui/UiSpinner.vue';
import UiSearchItem from '@/components/ui/UiSearchItem.vue';
import UiTabItem from '@/components/ui/UiTabItem.vue';

import {
  HASHTAG_RESULT,
  SEARCH_RESULT,
  USER_RESULT,
  recent,
} from '@/components/helpers/Search.stub';

export default {
  components: {
    UiTabItem,
    UiSearchItem,
    UiSpinner,
    UiIcon,
    AppSearch,
  },
  props: {
    container: Element,
    dismissible: Boolean,
  },
  data: function () {
    return {
      page: 1,
      hasMore: false,
      isLoading: false,
      q: this.$route.query.q || '',
      searchType: USER_RESULT,
      recent: [],
      results: [],
      scrollContainer: null,
    };
  },
  computed: {
    spinner() {
      return this.$store.state.spinner;
    },
    types() {
      return {
        hashtag: HASHTAG_RESULT,
        search: SEARCH_RESULT,
        user: USER_RESULT,
      };
    },
  },
  mounted() {
    this.load();
    this.scrollContainer = this.container ?? window;
    this.scrollContainer.addEventListener('scroll', this.updateScroll);
  },
  beforeDestroy() {
    this.scrollContainer.removeEventListener('scroll', this.updateScroll);
  },
  methods: {
    updateScroll() {
      const scrollPosition = this.container
        ? this.scrollContainer.scrollHeight - this.scrollContainer.clientHeight - this.scrollContainer.scrollTop
        : document.body.offsetHeight - window.innerHeight - window.scrollY;

      if (
        scrollPosition
        && scrollPosition <= 500
        && !this.isLoading
        && this.hasMore
      ) {
        this.loadMore();
      }
    },
    load() {
      if (this.q) {
        this.loadResults();
      } else {
        this.loadRecent();
      }
    },
    reset() {
      this.isLoading = false;
      this.results = [];
      this.hasMore = false;
      this.page = 1;
      this.load();
    },
    loadRecent() {
      this.recent = recent;

      // TODO: Implement search recent
      // ...
    },
    loadResults() {
      // this.results = results.filter((result) => result.type === this.searchType);
      // TODO: Implement search results
      // The code below is the legacy code from the old search,
      // it is intended to be modified to work with the API once it's ready.
      // ...
      if (this.page === 1) {
        this.$showSpinner();
      }

      this.isLoading = true;

      this.$get(
        '/search?query=' + encodeURIComponent(this.q) + '&page=' + this.page,
        (data) => {
          let results = [...this.results];
          for (let obj of data.data) {
            results.push(obj);
          }
          this.results = results;
          this.hasMore = data.next_page_url !== null;
          this.isLoading = false;
        },
        (errors) => {
          console.log(errors);
        },
      );
    },
    loadMore() {
      if (this.hasMore) {
        this.page = this.page + 1;
        this.load();
      }
    },
    onTabChange(type) {
      this.searchType = type;
      this.reset();
    },
    onDismiss(result) {
      // TODO: Remove 'result'
      // ...
      result = null;

      return result;
    },
  },
};
</script>
