<template>
  <div
    class="tw-relative tw-shrink-0 tw-rounded-full"
    :class="className({ size })"
    @click="onClick"
  >
    <component
      :is="to ? 'RouterLink' : 'span'"
      class="tw-h-full tw-w-full tw-flex tw-items-center tw-justify-center tw-align-middle tw-rounded-full tw-bg-gray-200 tw-overflow-hidden"
      :class="{ 'tw-cursor-pointer': editable }"
      :to="to"
    >
      <img
        v-if="src"
        :alt="alt"
        class="tw-h-full tw-w-full tw-object-cover"
        :src="src"
      >
      <span
        v-else-if="text"
        class="tw-uppercase tw-whitespace-nowrap"
        :style="{ fontSize }"
      >
        {{ text }}
      </span>
    </component>

    <div
      v-if="editable"
      class="
        tw-absolute tw-right-0 tw-bottom-0
        tw-h-[30%] tw-w-[30%]
      "
      :class="{ 'tw-cursor-pointer': editable }"
    >
      <UiIcon
        class="tw-relative tw-h-[100%] tw-w-[100%] tw-text-primary-500 tw-z-[2]"
        name="edit-square"
        solid
      />
      <div
        class="
          tw-absolute tw-top-1/2 tw-left-1/2 -tw-translate-x-1/2 -tw-translate-y-1/2
          tw-h-[75%] tw-w-[75%] tw-bg-white tw-rounded-lg tw-z-[1]
        "
      />
    </div>
    <div
      v-if="online"
      class="
        tw-absolute tw-right-0 tw-bottom-0
        tw-h-[30%] tw-w-[30%] tw-rounded-full
        tw-border-2 tw-border-white tw-bg-success-alt
      "
    />
  </div>
</template>

<script>
import { tv } from 'tailwind-variants';

import UiIcon from '@/components/ui/UiIcon.vue';

export default {
  components: { UiIcon },
  props: {
    alt: String,
    size: {
      type: [String, Object],
      required: true,
    },
    src: String,
    editable: Boolean,
    online: Boolean,
    text: String,
    to: [String, Object],
  },
  computed: {
    className() {
      return tv(
        {
          variants: {
            size: {
              xs: 'tw-h-[48px] tw-w-[48px]',
              sm: 'tw-h-[60px] tw-w-[60px]',
              md: 'tw-h-[100px] tw-w-[100px]',
              lg: 'tw-h-[120px] tw-w-[120px]',
            },
          },
        },
        {
          responsiveVariants: ['md', 'xl', '2xl'],
        },
      );
    },
    fontSize() {
      return `calc(${this.size} * 0.4)`;
    },
  },
  methods: {
    onClick() {
      if (this.editable) {
        this.$emit('click');
      }
    },
  },
};
</script>
